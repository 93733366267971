import { Button, Col, DatePicker, Form, Input, Radio, Row } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { BsLuggageFill } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { ImUserTie } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getDistance, getOriginDistance } from "../../redux/actions";
import "./styles.css";

const MAX_DISTANCE = 30000;

const searchOptions = {
  location: { lat: 49.26426, lng: 2.47029 }, // Center of the search
  radius: MAX_DISTANCE, // 15km in meters
  componentRestrictions: {
    country: "fr",
  },
};

const TravelForm = () => {
  const { forth, backAndForth } = useSelector((state) => state.reducer);
  const dispatch = useDispatch();
  const ref = useRef();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectorValue, setSelectorValue] = useState(false);
  const [originValue, setOriginValue] = useState(null);
  const [destinationValue, setDestinationValue] = useState(null);

  useEffect(() => {
    if (
      forth.origin &&
      forth.originLatLng &&
      forth.destination &&
      forth.destinationLatLng &&
      forth.date &&
      forth.person
    ) {
      setSelectorValue(backAndForth);
      setOriginValue(forth.origin);
      setDestinationValue(forth.destination);
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        origin: forth.origin,
        destination: forth.destination,
        date: dayjs(new Date(forth.date)),
        person: forth.person,
        luggage: forth.luggage,
      };
      form.setFieldsValue(fields);
    }
  }, [backAndForth, form, forth]);

  const onSelectOrigin = async (e) => {
    const address = e.label;
    const destination = await geocodeByAddress(address);
    const destinationLatLng = await getLatLng(destination[0]);
    const body = {
      originLatLng: {
        lat: 49.2656037,
        lng: 2.4713474,
      },
      destinationLatLng,
    };
    getOriginDistance(body).then((res) => {
      if (res.data > MAX_DISTANCE) {
        let fields = form.getFieldsValue();
        fields = {
          ...fields,
          origin: "",
        };
        setOriginValue("");
        form.setFieldsValue(fields);
        return toast("Nous ne pouvons pas prendre en charge cette course", {
          type: "error",
        });
      } else {
        let fields = form.getFieldsValue();
        fields = {
          ...fields,
          origin: address,
        };
        setOriginValue(e);
        form.setFieldsValue(fields);
      }
    });
  };

  const onSelectDestination = async (e) => {
    const address = e.label;
    let fields = form.getFieldsValue();
    fields = {
      ...fields,
      destination: address,
    };
    setDestinationValue(e);
    form.setFieldsValue(fields);
  };

  const onFinish = async (values) => {
    const origin = await geocodeByAddress(values.origin);
    const originLatLng = await getLatLng(origin[0]);
    const destination = await geocodeByAddress(values.destination);
    const destinationLatLng = await getLatLng(destination[0]);
    let body = {
      isFixePrice: false,
      backAndForth: selectorValue,
      forth: {
        ...values,
        originLatLng,
        destinationLatLng,
        date: new Date(values.date) + "",
      },
      back: {
        origin: values.destination,
        destination: values.origin,
        originLatLng: destinationLatLng,
        destinationLatLng: originLatLng,
        date: "",
        person: values.person,
        luggage: values.luggage,
      },
    };
    const goToReservation = () => navigate("/reservation");
    getDistance(body, goToReservation, dispatch);
  };

  return (
    <div className="travel_from_container">
      <div className="travel_from_selector">
        <Radio.Group value={selectorValue}>
          <Radio value={false} onChange={() => setSelectorValue(false)}>
            Aller simple
          </Radio>
          <Radio value={true} onChange={() => setSelectorValue(true)}>
            Aller/Retour
          </Radio>
        </Radio.Group>
      </div>
      <div>
        <Form
          className="travelForm"
          name="travel"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ origin: "", destination: "", person: 1, luggage: 0 }}
          form={form}
        >
          <Row gutter={5}>
            <Col xl={6} md={12} xs={24}>
              <Form.Item
                label="Adresse de départ"
                name="origin"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                htmlFor="originInput"
              >
                <GooglePlacesAutocomplete
                  id="originInput"
                  debounce={500}
                  minLengthAutocomplete={3}
                  autocompletionRequest={searchOptions} // Restrict search options
                  selectProps={{
                    value: originValue,
                    onChange: onSelectOrigin,
                  }}
                />
              </Form.Item>
            </Col>
            <Col xl={6} md={12} xs={24}>
              <Form.Item
                label="Adresse d'arrivée"
                name="destination"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                htmlFor="destinationInput"
              >
                <GooglePlacesAutocomplete
                  id="destinationInput"
                  debounce={500}
                  minLengthAutocomplete={3}
                  autocompletionRequest={searchOptions} // Restrict search options
                  selectProps={{
                    value: destinationValue,
                    onChange: onSelectDestination,
                  }}
                />
              </Form.Item>
            </Col>
            <Col xl={4} md={12} xs={24}>
              <Form.Item
                label="Date du départ"
                name="date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                htmlFor="dateInput"
              >
                <DatePicker
                  id="dateInput"
                  ref={ref}
                  format="DD/MM/YYYY HH:mm"
                  showTime={true}
                  placeholder="Date et heure de départ"
                  className="travel_from_container_input"
                  minDate={dayjs()}
                  initialValues={dayjs()}
                  suffixIcon={<FaCalendarAlt />}
                />
              </Form.Item>
            </Col>
            <Col xl={2} md={6} xs={24}>
              <Form.Item
                label="Personne"
                name="person"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  prefix={<ImUserTie style={{ marginRight: 5 }} />}
                  type="number"
                  placeholder="1"
                  min={1}
                  className="travel_from_container_input"
                />
              </Form.Item>
            </Col>
            <Col xl={2} md={6} xs={24}>
              <Form.Item
                label="Bagages"
                name="luggage"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  prefix={<BsLuggageFill style={{ marginRight: 5 }} />}
                  type="number"
                  placeholder="1"
                  min={0}
                  className="travel_from_container_input"
                />
              </Form.Item>
            </Col>
            <Col
              xl={4}
              md={24}
              xs={24}
              className="travel_from_button_container"
            >
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="travel_from_container_input"
                >
                  Consulter les prix
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default TravelForm;
