import { Button, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { filteredPrice } from "../../utils/filteredPrice";

const Prices = (props) => {
  const { forth, back, backAndForth, isFixePrice } = useSelector(
    (state) => state.reducer
  );
  const { setSelectedType } = props;
  return (
    <div className="prices_container">
      {forth?.price?.confort && (
        <div className="prices_content">
          <h2>Confort</h2>
          <Row gutter={20}>
            <Col span={8} className="flexCenter">
              <img
                className="prices_image"
                src={require("../../images/confort.webp")}
                alt="Confort"
                loading="lazy"
              />
            </Col>
            <Col span={8} className="flexCenter">
              <div className="price_text">
                {isFixePrice
                  ? backAndForth
                    ? filteredPrice(parseFloat(forth.price.confort * 2))
                    : filteredPrice(forth.price.confort)
                  : backAndForth
                  ? filteredPrice(
                      parseFloat(forth.price.confort) +
                        parseFloat(back.price.confort)
                    )
                  : filteredPrice(forth.price.confort)}
              </div>
            </Col>
            <Col span={8} className="flexCenter">
              <Button
                type="primary"
                className="selectButton"
                onClick={() => setSelectedType("Confort")}
              >
                Selectionner
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {forth?.price?.confort && (
        <div className="prices_content">
          <h2>Affaire</h2>
          <Row gutter={20}>
            <Col span={8} className="flexCenter">
              <img
                className="prices_image"
                src={require("../../images/business.webp")}
                alt="Business"
                loading="lazy"
              />
            </Col>
            <Col span={8} className="flexCenter">
              <div className="price_text">
                {isFixePrice
                  ? backAndForth
                    ? filteredPrice(parseFloat(forth.price.business * 2))
                    : filteredPrice(forth.price.business)
                  : backAndForth
                  ? filteredPrice(
                      parseFloat(forth.price.business) +
                        parseFloat(back.price.business)
                    )
                  : filteredPrice(forth.price.business)}
              </div>
            </Col>
            <Col span={8} className="flexCenter">
              <Button
                type="primary"
                className="selectButton"
                onClick={() => setSelectedType("Business")}
              >
                Selectionner
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <div className="prices_content">
        <h2>Van</h2>
        <Row gutter={20}>
          <Col
            span={8}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              className="prices_image"
              src={require("../../images/van.webp")}
              alt="Van"
              loading="lazy"
            />
          </Col>
          <Col span={8} className="flexCenter">
            <div className="price_text">
              {isFixePrice
                ? backAndForth
                  ? filteredPrice(parseFloat(forth.price.van * 2))
                  : filteredPrice(forth.price.van)
                : backAndForth
                ? filteredPrice(
                    parseFloat(forth.price.van) + parseFloat(back.price.van)
                  )
                : filteredPrice(forth.price.van)}
            </div>
          </Col>
          <Col span={8} className="flexCenter">
            <Button
              type="primary"
              className="selectButton"
              onClick={() => setSelectedType("Van")}
            >
              Selectionner
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Prices;
