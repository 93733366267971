import { Col, Image, Row } from "antd";
import React from "react";
import { AiFillTikTok } from "react-icons/ai";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./styles.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <Row gutter={20}>
          <Col lg={8} xs={24}>
            <div className="footer_left">
              <div style={{ display: "flex" }}>
                <Image
                  src={require("../../images/vtc-elite-transparent.webp")}
                  preview={false}
                  height={80}
                  width={80}
                  style={{ height: 80, width: 80 }}
                  alt="Mon transport Taxi VTC - Footer"
                />
                <div className="footer_left_text">
                  <h3 className="footer_title">Mon transport Taxi VTC</h3>
                  <p>Creil et ses alentours</p>
                  <p>06 12 34 56 78</p>
                  <p>accueil@montransporttaxivtc.fr</p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8} xs={24}>
            <div className="footer_center">
              <div>
                <h3 className="footer_title">Creil et 15km autours</h3>
                <p>Aéroport de Roissy Charles de Gaulle</p>
                <p>Aéroport de Orly</p>
                <p>Aéroport de Beauvais-Tillé</p>
              </div>
            </div>
          </Col>
          <Col lg={8} xs={24}>
            <div className="footer_right">
              <div>
                <h3 className="footer_title">Nous suivre</h3>
                <Link
                  to="https://www.facebook.com"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <FaFacebookSquare color="white" size={30} />
                </Link>
                <Link
                  to="https://www.instagram.com"
                  target="_blank"
                  aria-label="Instagram"
                >
                  <FaInstagramSquare color="white" size={30} />
                </Link>
                <Link
                  to="https://www.tiktok.com"
                  target="_blank"
                  aria-label="TikTok"
                >
                  <AiFillTikTok color="white" size={30} />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
