import React from "react";
import { TravelFormReservation } from "../../components";
import "./styles.css";

const Reserver = () => {
  return (
    <div className="reservation_container_page">
      <TravelFormReservation />
    </div>
  );
};

export default Reserver;
